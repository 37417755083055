class Error {
  type: string;
  message: string;

  constructor(data: any = {}) {
    this.type = data.type || '';
    this.message = data.message || '';
  }
}

class Sts {
  enabled: boolean;
  error: Error;

  constructor(data: any = {}) {
    this.enabled = data.enabled ?? false;
    this.error = new Error(data.error || {});
  }
}

class CostExplorer {
  enabled: boolean;
  lastCrawledAt: Date | null;
  error: Error;

  constructor(data: any = {}) {
    this.enabled = data.enabled ?? false;
    this.lastCrawledAt = data.last_crawled_at
      ? new Date(data.last_crawled_at)
      : null;
    this.error = new Error(data.error || {});
  }
}

class DataExports {
  enabled: boolean;
  lastCrawledAt: Date | null;
  error: Error;
  bucket: string;

  constructor(data: any = {}) {
    this.enabled = data.enabled ?? false;
    this.lastCrawledAt = data.last_crawled_at
      ? new Date(data.last_crawled_at)
      : null;
    this.error = new Error(data.error || {});
    this.bucket = data.bucket || '';
  }
}

class CostInsights {
  costExplorer: CostExplorer | undefined;
  dataExports: DataExports | undefined;

  constructor(data: any = {}) {
    this.costExplorer = data.cost_explorer
      ? new CostExplorer(data.cost_explorer)
      : undefined;
    this.dataExports = data.data_exports
      ? new DataExports(data.data_exports)
      : undefined;
  }
}

class EventbridgeInsights {
  enabled: boolean;
  services: string[];
  error: Error;
  bucket: string;
  templateUrl: string;

  constructor(data: any = {}) {
    this.enabled = data.enabled ?? false;
    this.services = data.services || [];
    this.error = new Error(data.error || {});
    this.bucket = data.bucket || '';
    this.templateUrl = data.template_url || '';
  }
}

export class IntegrationAwsV2 {
  externalId: string;
  accountId: string;
  createdAt: Date;
  enabled: boolean;
  roleArn: string;
  integratedAt: Date;
  sts: Sts;
  costInsights: CostInsights;
  eventbridgeInsights: EventbridgeInsights;

  constructor(data: any = {}) {
    this.externalId = data.external_id;
    this.accountId = data.account_id;
    this.createdAt = data.created_at ? new Date(data.created_at) : new Date();
    this.enabled = data.enabled ?? false;
    this.roleArn = data.role_arn;
    this.integratedAt = data.integrated_at
      ? new Date(data.integrated_at)
      : new Date();
    this.sts = new Sts(data.sts || {});
    this.costInsights = new CostInsights(data.cost_insights || {});
    this.eventbridgeInsights = new EventbridgeInsights(
      data.eventbridge_insights || {}
    );
  }
}
