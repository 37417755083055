import { DateTime } from 'luxon';
import timezone from '@/models/global/timezone';

// luxonをDateと同じインターフェースでつかえるようにするモデル
export class DateWithZone {
  model: DateTime;

  constructor(
    text: string | number = '',
    month?: number,
    date?: number,
    hour?: number,
    minute?: number,
    second?: number
  ) {
    if (typeof text === 'number') {
      if (!month) {
        // エポック秒を指定して生成する
        this.model = DateTime.fromMillis(text, {
          zone: timezone.getters.getTimezone,
        });
      } else {
        // 年月日を指定して生成する
        this.model = DateTime.fromObject(
          {
            year: text,
            month: month,
            day: date || 1,
            hour: hour || 0,
            minute: minute || 0,
            second: second || 0,
          },
          {
            zone: timezone.getters.getTimezone,
          }
        );
      }
    } else {
      if (text.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
        // 日付のみの場合は、設定されたタイムゾーンの00:00:00にする
        this.model = DateTime.fromISO(text + 'T00:00:00.000', {
          zone: timezone.getters.getTimezone,
        });
      } else if (text === '' || (!text.includes('Z') && !text.includes('+'))) {
        // デフォルトで生成する
        this.model = DateTime.fromJSDate(new Date(), {
          zone: timezone.getters.getTimezone,
        });
      } else {
        // ISO形式の文字列を指定して生成する
        this.model = DateTime.fromISO(text, {
          zone: timezone.getters.getTimezone,
        });
      }
    }
  }
  // DateからDateWithZoneに変換する
  static fromJSDate(date: Date) {
    return new DateWithZone(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
  }

  getFullYear(): number {
    return this.model.year;
  }
  getMonth(): number {
    return this.model.month - 1;
  }
  getDate(): number {
    return this.model.day;
  }
  getHours(): number {
    return this.model.hour;
  }
  getMinutes(): number {
    return this.model.minute;
  }
  getSeconds(): number {
    return this.model.second;
  }
  getTime(): number {
    return this.model.toMillis();
  }
  getTimeInMinutes(): number {
    return Math.floor(this.model.valueOf() / (1000 * 60));
  }
  getTimeToMinutesByMillisecond(): number {
    return this.model.startOf('minute').toMillis();
  }
  setDate(date: number): number {
    this.model = this.model.set({ day: date });
    return this.model.toMillis();
  }
  setMonth(month: number): number {
    this.model = this.model.set({ month: month + 1 });
    return this.model.toMillis();
  }
  getDateObject(): Date {
    const date = new Date(
      this.model.year,
      this.model.month - 1,
      this.model.day,
      this.model.hour,
      this.model.minute,
      this.model.second
    );
    return date;
  }
  toISOString(): string {
    return this.model.toISO() || '';
  }
  toISODate(): string {
    return this.model.toISODate() || '';
  }
}
